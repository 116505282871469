import { useState, useEffect, useCallback, useRef } from 'react';

import Cookie from 'js-cookie';
import classnames from 'classnames';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';

import * as DatahubService from '../../utils/datahub';
import { DotButton, PrevButton, NextButton } from './Buttons';
import { EVENT_ACTIONS, CLICK_SOURCES } from '../../utils/datahub.constants';

function Slider({
  children,
  showDots,
  autoplaySpeed,
  showArrows = true,
  dragFree = true,
  total = 5,
  loop,
  className,
  isOnScreen = false,
  sliderData,
  isBlackNovember = false,
  source = ''
}) {
  const productsDesktop = 4;
  const showArrowsCarousel = showArrows && total > productsDesktop || false;
  const [viewportRef, embla] = useEmblaCarousel({ loop, dragFree, containScroll: 'trimSnaps', align: 'start' }, autoplaySpeed && [autoplayOption.current]);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const recomendationTypes = {
    'SECTION_HOME_MARKETPLACE_ALL': 'all_products',
    'SECTION_HOME_MARKETPLACE_BEST_RATED': 'best_rated',
    'SECTION_HOME_MARKETPLACE_BEST_SELLERS': 'most_sales'
  }

  const autoplayOption = useRef(
    Autoplay(
      { delay: autoplaySpeed, stopOnInteraction: false, stopOnMouseEnter: true },
      (emblaRoot) => emblaRoot.parentElement
    )
  );

  let viewedItemIndex = 3;

  const handleScrollNext = shouldSendEvent => {
    if (shouldSendEvent) {
      const user = JSON.parse(localStorage.getItem('cas-js:user'));
      const previousSlides = embla.slidesInView();
      const newSlideIndex = previousSlides[previousSlides.length - 1] + 1;
      const viewSource = isBlackNovember
        ? CLICK_SOURCES.HOME_BEST_RATED_BLACK_NOVEMBER
        : source;

      if (newSlideIndex > viewedItemIndex) {
        const newSlideData = sliderData[newSlideIndex];

        DatahubService.sendEvent({
          action: EVENT_ACTIONS.VIEW,
          data_version: '2.0',
          sessionId: Cookie.get('sid'),
          recomendationType: recomendationTypes[viewSource] ?? viewSource,
          source: viewSource,
          productId: newSlideData?.productId,
          productPosition: newSlideIndex,
          creatorId: newSlideData?.owner?.id,
          buyerId: user?.profile?.id || null,
          buyerIsLogged: Boolean(user?.profile),
          trafficSource: DatahubService.getDetailedTrafficSource(document?.referrer),
          url: document.location.href,
        });

        viewedItemIndex = newSlideIndex;
      }
    }
  };


  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => {
    if (embla) {
      embla.scrollNext();
      handleScrollNext(isOnScreen);
    }
  }, [embla, isOnScreen]);
  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [
    embla
  ]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={classnames('embla', { 'embla__no-carousel': total <= productsDesktop })}>
        <div className="embla__viewport" ref={viewportRef}>
          <div className={classnames('embla__container', className)}>
            {children}
          </div>
        </div>
        {showArrowsCarousel && (
          <>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
          </>
        )}
      </div>
      {showDots && total > 1 && (
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default Slider;
