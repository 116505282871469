import { FILTER } from '../constants';

export const isBestRated = (rating: number) => {
  return rating >= 3.5;
};

const isPaymentAccount = (payment: string) => {
  return payment === FILTER.PAYMENT.ACCOUNT;
};

export const isProductHighlighted = (typePayment) => {
  return typePayment?.some(payment => isPaymentAccount(payment));
};