import Icons from '../../../Icons';
import { RatingProps } from './types';

function Rating({ rating, totalReviews }: RatingProps) {
  return rating ? (
    <div className="product-card__rating">
      <span>{rating.toFixed(1)}</span>
      <Icons
        className="product-card__rating__star"
        iconName="star"
        prefix="fas"
      />
      {totalReviews && (
        <span className="product-card__rating__total">
          ( {totalReviews} )
        </span>
      )}
    </div>
  ) : null;
}

export default Rating;
